import React from "react";
import { IconDefinition,  faQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toast } from "react-hot-toast";
import { COLOURS } from "../common/colours";

/*
 * example
            toast.custom(t => <TailwindToast t={t} title="Error" icon={faXmark} bg={'red'} />)

            options if needed: { position: 'top-right', duration: Infinity }
*/

const TailwindToast = ({ t, title, description, bg = 'green', icon, timing }:
    { t: Toast, title: string, description?: string, icon?: IconDefinition, bg?: COLOURS, timing?: number }) => {

    if (!!timing && timing > 0) {
        setTimeout(() => toast.dismiss(t.id), (timing - 1) * 1000)
    }
    return (
        <div
            className={`${t.visible ? 'animate-enter' : 'animate-leave'
                } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5`}
        >
            <div className="flex">
                <div className="flex-1 w-0 p-4">
                    <div className="flex items-center">
                        <div className="flex-shrink-0 pt-0.5">
                            <div className={`h-10 w-10 rounded-full flex justify-center items-center ${bg === 'green' ? `bg-green-600` :
                                bg === 'red' ? 'bg-red-700' :
                                    bg === 'orange' ? 'bg-orange-700' :
                                        'bg-gray-600'}`}>
                                <FontAwesomeIcon
                                    className="h-8 w-8 text-white"
                                    icon={icon || faQuestion}
                                    fixedWidth
                                />
                            </div>
                        </div>
                        <div className="ml-3 flex-1">
                            <p className="text-sm font-medium text-gray-900">
                                {title}
                            </p>
                            {!!description && <p className="mt-1 text-sm text-gray-500">
                                {description}
                            </p>}
                        </div> 
                    </div>
                </div>
                <div className="flex border-l border-gray-200">
                    <button
                        onClick={() => toast.dismiss(t.id)}
                        className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Close
                    </button>
                </div>
            </div>
            {!!timing && timing > 0 &&
                <div className="progress-bar h-2 w-full bg-gray-300 rounded-b-lg">
                    <div className={`progress-bar-inner h-2 rounded-b-lg progress ${timing === 5 ? `animate-progress5 ${bg === 'green' ? `bg-green-600` :
                        bg === 'red' ? 'bg-red-700' :
                            bg === 'orange' ? 'bg-orange-700' :
                                'bg-blue-600'}` :
                        timing === 4 ? `animate-progress4` :
                            timing === 3 ? `animate-progress3` :
                                timing === 2 ? `animate-progress2` :
                                    ``} `}></div>
                </div>
            }
        </div >
    )
}

export default TailwindToast