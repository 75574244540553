import React from 'react';
import { COLOURS } from '../common/colours';
import classNames from 'classnames';

const LoadingCircle = (
  { colour, classname, percentage = 100 }: { colour?: COLOURS, classname?: string, percentage?: number }
) => {

  return (
    <div className="relative">
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <circle
          className="text-gray-200 stroke-current"
          strokeWidth="10"
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
        ></circle>
        <circle
          className={classNames(
            "progress-ring__circle stroke-current",
            classname,
            !colour ? 'text-blue-500' : `text-${colour}-500`,
          )}
          strokeWidth="10"
          strokeLinecap="round"
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
          strokeDasharray="251.2"
          strokeDashoffset={`calc(251.2 - (251.2 * ${percentage}) / 100)`}
        ></circle>

        <text x="50" y="50" fontFamily="Verdana" fontSize="12" textAnchor="middle" alignmentBaseline="middle" fill="white">{percentage}%</text>

      </svg>
    </div>
  );
};

export default LoadingCircle;