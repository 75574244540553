import { useState, createContext, useContext, useEffect } from 'react'
import { urls } from "../common";
import axios from 'axios';
import LoadingDots from '../components/LoadingDots';
import { useGlobal } from './globals';
import { PropsDocument } from '../Pages/Documents/types';
import { PropsError } from '../Pages/Errors/types';
import Error from '../Pages/Errors';


interface documentContext {
  document: PropsDocument | null;
  setDocument: React.Dispatch<React.SetStateAction<PropsDocument | null>>;
  setRefresh: Function
}

const DocumentContext = createContext<documentContext>({} as documentContext);


export const DocumentProvider = ({ id, children }: { id: string, children: any }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [document, setDocument] = useState<PropsDocument | null>(null);
  const [refresh, setRefresh] = useState<boolean>();
  const [error, setError] = useState<PropsError>();
  const { setGlobal } = useGlobal();

  useEffect(() => {
    setLoading(true);
    if (id) {
      axios.get(`${urls.remoteURL}document/${id}`)
        .then(({ data }) => {
          setGlobal(prev => ({ ...prev, course: data }));
          setDocument({ ...data, id });
        })
        .catch((e) => {
          setError(e.response.data);
          setDocument(null);
        })
        .finally(() => {
          setLoading(false)
        });
    } else {
      setLoading(false);
      setDocument(null);
    }
  }, [id, refresh]);

  return (
    <DocumentContext.Provider value={{ document, setDocument, setRefresh }}>
      {loading ? <LoadingDots /> : error ? <Error status={error.code} message={error.message} /> : children}
    </DocumentContext.Provider>
  );
}

export const useDocument = () => useContext(DocumentContext);