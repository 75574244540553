import React, { LabelHTMLAttributes } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/pro-solid-svg-icons";

interface PropsLabel extends LabelHTMLAttributes<HTMLLabelElement> {
  className?: string,
  required?: boolean,
  children: any,
}


const Label = (props: PropsLabel) => {
  const { className, required, children, ...rest } = props;

  return (
    <label className={classNames("label", className)} {...rest}>
      {children} {required && <span className="text-red-700 align-super text-[8px]"><FontAwesomeIcon icon={faAsterisk}  /></span>}
    </label>
  );
};

export default Label;
