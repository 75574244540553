import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import toast from "react-hot-toast";
import { COLOURS } from "./colours";
import TailwindToast from "../components/TailwindToast";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons";
import numeral from "numeral";


export const myToast = ({ title, message, icon, colour, timing }: { title: string, message?: string, icon?: IconDefinition, colour?: COLOURS, timing?: number }) => {
  return toast.custom(t => <TailwindToast t={t} title={title} description={message} icon={icon || faQuestion} bg={colour} timing={timing} />)
}

export const _formatNumbers = (
  { n, convert = true, alt = 'free', format = '0,00.00', prefix }:
    { n: number, convert?: boolean, alt?: string | number, format?: string, prefix?: string }
) => {
  if (!n || n === 0) { return alt; }
  return (prefix ? prefix : '') + numeral(convert ? n / 100 : n).format(format)
}

export const _isTouchScreen = () => 'ontouchstart' in window || window.matchMedia('(any-pointer: coarse)').matches;