import { useState, createContext, useContext } from 'react'

type globalType = {
    menuOpen: boolean,
    // currentCompany?: PropsCompany,
    // currentUser?: PropsUser,
}

interface globalContext {
    global: globalType;
    setGlobal: React.Dispatch<React.SetStateAction<globalType>>;
}

const GlobalContext = createContext<globalContext>({} as globalContext);

export const GlobalProvider = ({ children }: { children: any }) => {
    const [global, setGlobal] = useState<globalType>({
        menuOpen: true
    });

    return (
        <GlobalContext.Provider value={{ global, setGlobal }}>
            {children}
        </GlobalContext.Provider >
    );
}

export const useGlobal = () => useContext(GlobalContext);