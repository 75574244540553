import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useUser } from "../context/userProvider";


const RequireAuth = ({ children }: { children?: JSX.Element }) => {
    const { user } = useUser();
    const location = useLocation();

    if (!user?.email) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return children || <Outlet />;
}

export default RequireAuth;