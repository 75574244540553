import { useState, createContext, useContext, useEffect } from 'react'
import { urls } from "../common";
import axios, { AxiosError } from 'axios';
import LoadingDots from '../components/LoadingDots';
import { PropsUser } from '../Pages/Users/types';


interface adminContext {
  admin: PropsUser | null;
  setAdmin: React.Dispatch<React.SetStateAction<PropsUser | null>>;
  setRefresh: Function
}

const AdminContext = createContext<adminContext>({} as adminContext);

export const AdminProvider = ({ id, children }: { id: string, children: any }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [admin, setAdmin] = useState<PropsUser | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (id && id !== 'create') {
      axios.get(`${urls.remoteURL}user/${id}`)
        .then(({ data }) => {
          setAdmin({ ...data, id });
        })
        .catch((e) => {
          setAdmin(null);
        })
        .finally(() => {
          setLoading(false)
        });
    } else {
      setLoading(false);
      setAdmin(null);
    }
  }, [id, refresh]);

  return (
    <AdminContext.Provider value={{ admin, setAdmin, setRefresh }}>
      {loading ? <LoadingDots /> : children}
    </AdminContext.Provider>
  );
}

export const useAdmin = () => useContext(AdminContext);