import React from "react";
import { format } from 'date-fns'
import { Outlet } from "react-router-dom";
import { useUser } from "../context/userProvider";
import Header from "./layout/Header";

const Wrapper = () => {
    const { user } = useUser();

    return (
        <div className="min-h-screen w-full bg-gray-50 relative">
            {user && <Header />}
            <div className={`${user ? `px-4 pt-20` : `px-0`} pb-20`}>
                <Outlet />
            </div>
            <footer className="fixed bottom-0 w-full">
                <div className="mx-auto px-4 sm:px-6 lg:px-8 w-full bg-gray-900">
                    <div className="py-4 text-sm text-white text-center sm:text-left">
                        <span className="block sm:inline">&copy; {format(new Date(), 'yyyy')} Coaching Culture.</span>{' '}
                        <span className="block sm:inline">All rights reserved.</span>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Wrapper;