import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../../context/userProvider";

const LogoutPage = () => {
    const {user, setToken, setUser} = useUser();
    useEffect(() => {
        if (user != null) {
            setToken('');
            setUser(null);
        }
    });

    return (
        <Navigate to={'login'} />
    );
};

export default LogoutPage;
