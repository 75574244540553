import React from "react";
import Tippy from "@tippyjs/react";
import { Placement } from "tippy.js"

interface DropdownProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  content: any;
  placement?: Placement;
  arrow?: boolean;
  trigger?: string;
  show?: boolean;
  onClickOutside?: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  content,
  children,
  placement = "bottom-start",
  arrow = true,
  trigger = 'click',
  show,
  ...rest
}) => {
  return (
    <Tippy
      content={content}
      theme="light-border"
      offset={[0, 8]}
      placement={placement}
      interactive
      allowHTML
      animation="shift-toward-extreme"
      appendTo={document.body}
      arrow={arrow}
      trigger={trigger === 'manual' ? undefined : trigger}
      visible={show}
      maxWidth={'100vw'}
      {...rest}
    >
      {children}
    </Tippy>
  );
};

export default Dropdown;
