import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBiohazard, faEnvelope, faEye, faEyeSlash, faFileSearch } from "@fortawesome/pro-solid-svg-icons";


import Label from "../../components/form/Label";
import Input from "../../components/form/Input";
import Button from "../../components/Button";

import { urls } from "../../common";
import classNames from "classnames";
import { useUser } from "../../context/userProvider";
import { myToast } from "../../common/Toast";

type PropsInputs = { email: string, password: string }

const LoginPage = () => {
    const location = useLocation();
    const { user, setToken } = useUser();

    const schema = yup
        .object()
        .shape({
            email: yup.string().email().required(),
            password: yup.string().required(),
        });


    const { register, handleSubmit, formState: { errors }, getValues, setError } = useForm<PropsInputs>({
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
    });


    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [disableForgotten, setDisableForgotten] = useState<boolean>(false);


    const path = location.state?.from.pathname && !location.state.from.pathname.includes('logout') ? location.state.from.pathname : '/';

    const [disabled, setDisabled] = useState<boolean>(false);

    const onSubmit = (data: PropsInputs) => {
        setDisabled(true);
        axios.post(`${urls.remoteURL}login`, data)
            .then((x) => {
                setToken(x.data);
            })
            .catch(({ response }) => {
                myToast({
                    title: 'Error',
                    message: response.data?.message || 'Please check you have filled everything in correctly.',
                    colour: 'red',
                    icon: faBiohazard
                });
            })
            .finally(() => {
                setDisabled(false);
            });
    }

    const onError = (error: any) => {
        // console.log(error);
    }

    const forgottenPassword = () => {
        const email = getValues('email');
        if (!email) {
            myToast({ title: 'Forgotten Password?', message: 'Prease fill in email address.', icon: faEnvelope, colour: 'red', timing: 3 })
            setError('email', { message: 'Please fill in email' });
            //     setErrorMessage('Please fill in email.')
        } else {
            setDisableForgotten(true);
            axios.patch(`${urls.remoteURL}forgotten-password`, { email })
                .then((x) => {
                    myToast({ title: 'Forgotten Password?', message: 'Check your email to reset your password!', icon: faEnvelope, colour: 'green' })
                })
                .catch(({ response }) => {
                    setDisableForgotten(false);
                    myToast({ title: 'Forgotten Password?', message: 'Prease fill in email address.', icon: faEnvelope, colour: 'red' })
                })
                .finally(() => {

                });
        }
    }


    if (user) {
        return (
            <Navigate to={path} />
        );
    }

    return (
        <div className="container  py-10">

            <div className="flex items-center justify-center">
                <div className="w-full md:w-1/2 xl:w-1/3">


                    <div className="bg-gray-800 rounded-lg max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between">
                        <img src={'/images/cc-white-logo.png'} alt="" className={`max-h-12`} />
                        <FontAwesomeIcon icon={faFileSearch} className="h-12 text-white" />
                    </div>

                    <form className="card mt-5 p-5 md:p-10" onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="mb-5">
                            <Label className={classNames("block mb-2", { 'invalid': errors.email })} htmlFor="email">
                                Email
                            </Label>
                            <Input
                                id='email'
                                autoComplete="on"
                                {...register('email')}
                                placeholder="example@example.com"
                                className={classNames({ 'invalid': errors.email })} />
                        </div>
                        <div className="mb-5">
                            <Label htmlFor="password" className={classNames("block mb-2", { 'invalid': errors.password })}>
                                Password
                            </Label>
                            <label className="form-control-addon-within focus-within">
                                <Input
                                    id="password"
                                    {...register('password')}
                                    type={isPasswordVisible ? "text" : "password"}
                                    className="border-none"
                                />
                                <span className="flex items-center ">
                                    <button
                                        type="button"
                                        className="text-gray-300 dark:text-gray-700 text-xl leading-none pr-2"
                                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                    ><FontAwesomeIcon icon={isPasswordVisible ? faEye : faEyeSlash} fixedWidth /></button>
                                </span>
                            </label>
                        </div>
                        <div className="flex items-center">
                            <Button type="button" color={'gray'} className="text-sm uppercase" onClick={forgottenPassword} disabled={disableForgotten}>
                                Forgot Password?
                            </Button>
                            <Button type="submit" className="ml-auto  uppercase" disabled={disabled}>
                                Login
                            </Button>
                        </div>
                    </form>
                </div >
            </div >
        </div >
    )
}

export default LoginPage;
