import React, { useEffect, useState } from 'react';
import Breadcrumb, { BreadcrumbItem } from "../../components/Breadcrumb";
import { useParams } from 'react-router-dom';
import type { Params } from 'react-router-dom';
import { useDocument } from '../../context/documentProvider';

function DocumentBreadcrumbs() {
    const params = useParams<Params>();
    const [current, setCurrent] = useState<string[]>();

    const { document } = useDocument();

    const [items, setItems] = useState<JSX.Element[]>();

    useEffect(() => {
        if (!document) {
            setItems([]);
            return;
        }

        const breadcrumbItems: JSX.Element[] = [
            <BreadcrumbItem key="view-all" link="">View All</BreadcrumbItem>,
            <BreadcrumbItem key="company" link={`/${document.id}`}>{document?.name}</BreadcrumbItem>
        ];

        if (current?.includes('edit') && Object.values(params).length == 2) {
            breadcrumbItems.push(<BreadcrumbItem key="edit" >Edit</BreadcrumbItem>);
        }

        setItems(breadcrumbItems);
    }, [document, current])

    useEffect(() => {
        if (params['*']) {
            const a = params['*'].split('/');
            setCurrent(a);
        } else {
            setCurrent([]);
        }
    }, [params])


    if (!document && !items) {
        return (<Breadcrumb title="Documents" />)
    }

    return (
        <>
            <Breadcrumb title="Documents">
                {items?.map(x => x)}
            </Breadcrumb>
        </>
    );
}

export default DocumentBreadcrumbs;