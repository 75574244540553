import React, { useState } from 'react';
import { FieldValue, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from "axios";

import { fileIcon, getFileExtension, getFilenameWithoutExtension, urls } from "../../common";
import { faBiohazard, faSave, faThumbsUp, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../components/form/Label';
import classNames from 'classnames';
import Input from '../../components/form/Input';
import TextArea from '../../components/form/Textarea';
import { useDocument } from '../../context/documentProvider';
import { PropsDocument } from './types';
import SwitchControl from '../../components/form/Switch';
import Tooltip from '../../components/Tooltip';
import Button from '../../components/Button';
import { myToast } from '../../common/Toast';
import { Link } from 'react-router-dom';

function DocumentForm({ MenuElement }: { MenuElement?: JSX.Element }) {
    const { document, setDocument } = useDocument();
    const [file, setFile] = useState<File | null>(null);
    const [dragActive, setDragActive] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);

    const schema = yup
        .object()
        .shape({
            name: yup.string().required(),
            download_name: yup.string().required(),
        });


    const { register, handleSubmit, formState: { errors }, control, } = useForm<PropsDocument>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: document?.name,
            description: document?.description || '',
            download_name: getFilenameWithoutExtension(document?.download_name || document?.filename as string),
            active: document?.active,
        } as PropsDocument
    });

    const submit = (data: FieldValues) => {
        const formData = new FormData();
        if (file) { formData.append("upload", file); }
        for (const key of Object.keys(data)) {
            formData.append(key, data[key]);
        }



        axios.put(`${urls.remoteURL}document/${document?.id}`, formData,
            {
                headers: { "Content-Type": "multipart/form-data" },

            }).then(res => {
                setFile(null);
                setFileSelected(false);
            })
            .then(() => {
                myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 });
            })
            .catch((error) => {
                myToast({ title: 'Error', message: error.response.data.message || 'Error saving document?', colour: 'red', icon: faBiohazard, timing: 3 });
            })
    }


    const handleDrag = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files.length > 1) {
            alert('Please choose one file')
            return false;
        }

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleChangeFile(e.dataTransfer.files[0])
        }
    };

    const handleChangeFile = (file: File | null) => {
        setFile(file);
        setFileSelected(true);
    }


    return (
        <form onSubmit={handleSubmit((d) => submit(d))}>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between">
                <div>
                    {MenuElement}
                </div>
                <div className=" my-3 sm:mt-0 sm:ml-4 gap-2 flex">
                    <Link to="../.." className="btn px-6 rounded-full">Back</Link>
                    <Button
                        color="green"
                        type="submit"
                        className="px-6 rounded-full"
                    >
                        Save <FontAwesomeIcon icon={faSave} fixedWidth className="ml-2" />
                    </Button>
                </div>
            </div>

            <div className="grid md:grid-cols-2 gap-4 mb-4 ">
                <div className="card p-5 space-y-4">
                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.name })} required htmlFor="name">
                            Name
                        </Label>
                        <Input
                            {...register('name')}
                            placeholder="Document Name"
                            className={classNames({ 'invalid': errors.name })} />
                    </div>

                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.download_name })} required>
                            Filename
                        </Label>
                        <div className="flex">
                            <Input
                                {...register('download_name')}
                                placeholder="Filename"
                                className={classNames('rounded-r-none', { 'invalid': errors.download_name })} />
                            <span className="form-control border-l-0 max-w-[80px] rounded-l-none rounded-r-md">.{getFileExtension(document?.filename as string)}</span>
                        </div>
                    </div>

                    <div>
                        <Label className={classNames("block mb-2")} htmlFor="description">
                            Description
                        </Label>
                        <TextArea
                            {...register('description')}
                            rows={4}
                        />
                    </div>


                    <div className="flex items-center">
                        <div className="flex-1">
                            <Label className={classNames("block")} htmlFor="firstname">
                                Active
                            </Label>
                            <small className="text-muted">Will hide document from client side.</small>
                        </div>
                        <div className="">
                            <SwitchControl
                                name="active"
                                // defaultValue={!!document?.active as unknown as string}
                                control={control}
                            />
                        </div>
                    </div>


                </div>

                <div className="card p-5 space-y-4">
                    <h3>Replace File</h3>
                    {!fileSelected && !file ?
                        <div onDragEnter={handleDrag} className="relative flex items-center justify-center w-full" >
                            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-44 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                </div>
                                <input id="dropzone-file" type="file" className="hidden" onChange={e => { if (e.target.files) { handleChangeFile(e.target.files[0]); } }} />
                            </label>
                            {dragActive && <div className="absolute w-full h-full top-0 left-0 right-0 bottom-0 opacity-50 bg-blue-600 rounded-lg" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                        </div >
                        :
                        <>
                            <div className="flex gap-2 items-center ">
                                <div className="flex items-center gap-2">
                                    <div className="bg-green-700 p-2 rounded !w-auto">
                                        <FontAwesomeIcon icon={fileIcon(file as File)} className={'text-white'} fixedWidth />
                                    </div>
                                    {file?.name}
                                </div>
                                <Tooltip content={'Remove File'}>
                                    <Button color="red" className="rounded-full" onClick={() => { setFile(null); setFileSelected(false) }}><FontAwesomeIcon icon={faXmark} fixedWidth /></Button>
                                </Tooltip>
                            </div>
                            <p className="text-sm italic">Click save to replace file!</p>
                        </>
                    }
                </div>


            </div>


        </form>
    )

}

export default DocumentForm;