import React, { useEffect, useState } from "react";
import Label from "../../components/form/Label";
import classNames from "classnames";
import Input from "../../components/form/Input";
import { PropsUser } from "./types";

type formProps = { firstname: string, lastname: string, email: string, admin?: string };

const AdminAddForm = ({ inputID, userType, updated }: { inputID: string, userType?: boolean, updated?: (user: PropsUser) => void }) => {
    const [json, setJSON] = useState({});
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (typeof updated === 'function') { updated({ firstname, lastname, email }) }

        setJSON({ firstname, lastname, email });
    }, [firstname, lastname, email])

    return (
        <form onSubmit={(e) => { e.preventDefault(); }}
            className="text-left space-y-4">
            <div >
                <Label className={classNames("block mb-2")} required htmlFor="firstname">
                    Name
                </Label>
                <div className="grid grid-cols-2 gap-2">
                    <Input
                        placeholder="John"
                        onChange={(e) => setFirstname(e.target.value)}
                    />
                    <Input
                        placeholder="Doh"
                        onChange={(e) => setLastname(e.target.value)}
                    />
                </div>
            </div>

            <div>
                <Label className={classNames("block mb-2")} required htmlFor="email">
                    Email
                </Label>
                <Input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="email@domain.com"
                />
            </div>

            <input type="hidden" readOnly id={inputID} value={JSON.stringify(json)} />
        </form>
    );
}

export default AdminAddForm;