
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';
import { getFileExtension, urls } from '../../common';
import { useParams } from 'react-router-dom';
import fileDownload from 'js-file-download';
import LoadingDots from '../../components/LoadingDots';
import ProgressBar from '../../components/Progress';

import type { PDFDocumentProxy } from 'pdfjs-dist';
import { useUser } from '../../context/userProvider';
import LoadingCircle from '../../components/LoadingCircle';
import Pagination from '../../components/Pagination';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'js/pdf.worker.min.mjs',
//     import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = new URL(urls.URL + `js/pdf.worker.min.mjs`).toString();

type PDFFile = string | File | null;

function Downloading() {
    const { token } = useUser();
    const { documentID, filename } = useParams();
    const { referrer } = document;
    const [progress, setProgress] = useState(0);
    const [downloaded, setDownloaded] = useState(false);
    const [error, setError] = useState(false);
    const [showProgress, setShowProgress] = useState(false);


    const [numPages, setNumPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [file, setFile] = useState<PDFFile>(`${urls.remoteURL}get-download/${documentID}`);
    const extension = getFileExtension(filename as string);
    const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
    const [containerWidth, setContainerWidth] = useState<number>();

    const options = useMemo(() => {
        return {
            // cMapUrl: '/cmaps/',
            // standardFontDataUrl: '/standard_fonts/',
            error: 'Oops',
            httpHeaders: {
                'url-check': referrer ? new URL(referrer).hostname : 'https://share.planhat.com',
                'authorization': token ? 'Bearer ' + token : null
            }
        };
    }, [])

    const resizeObserverOptions = {};

    const maxWidth = 800;

    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);
    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    const handleDownload = () => {
        setProgress(0);
        setShowProgress(true);
        const domain = referrer ? (new URL(referrer)) : { hostname: '' };
        axios.get(`${urls.remoteURL}get-download/${documentID}`,
            {
                params: { referrer: domain.hostname }, responseType: 'blob',
                onDownloadProgress: progress => {
                    const { total, loaded } = progress;
                    const uploadPercentage = Math.round((loaded * 100) / (total as number));
                    if (!downloaded) {
                        setProgress(prev => prev < 100 ? uploadPercentage : prev);
                    }
                }
            })
            .then(({ data }) => {
                fileDownload(data, filename as string)
                setDownloaded(true);
                setProgress(100);
                setTimeout(() => {
                    setShowProgress(false);
                }, 3000);
            })
            .catch(async error => {
                // const responseObj = JSON.parse(await error.response.data.text());
                // console.log(responseObj.message);
                setError(true);
            });
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
        setNumPages(nextNumPages);
    }


    useEffect(() => {
        if (extension !== 'pdf') {
            setTimeout(() => {
                handleDownload();
            }, 300);
        
        }
    }, [])

    return (
        <div className="container  py-10">

            <div className="flex items-center justify-center">
                <div className="w-full md:w-1/2">
                    <div className="bg-gray-800 rounded-lg max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between">
                        <img src={'/images/cc-white-logo.png'} alt="" className={`max-h-12`} />
                        <div className="gap-4 flex items-baseline">
                            <span className="text-white text-lg">{filename}</span>
                            {!showProgress ? <button onClick={handleDownload} className='m-0 p-0 cursor-pointer'><FontAwesomeIcon icon={faFileDownload} className="h-12 text-white" /></button> :
                                <div className="h-14 w-14"><LoadingCircle percentage={progress} /></div>
                            }
                        </div>
                    </div>

                    <div className="card mt-5 p-5 md:p-10">
                        {extension === 'pdf' ?
                            <div className="Example__container__document" ref={setContainerRef}>

                                <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                                    <Page
                                        className={'border overflow-hidden rounded-lg'}
                                        key={`page_${currentPage}`}
                                        pageNumber={currentPage}
                                        width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                    />
                                </Document>
                                {numPages > 1 &&
                                    <div className="flex justify-center">
                                        <Pagination
                                            currentPage={currentPage - 1}
                                            pageSize={1}
                                            hidePageSize={true}
                                            totalCount={numPages as number}
                                            onPageChange={(value) => {
                                                setCurrentPage((value as number) + 1)
                                            }}
                                            compact={true}
                                        />
                                    </div>
                                }
                            </div> :


                            !error ?
                                <>
                                    <div className="flex justify-between">
                                        <h3 className="mb-5">Downloading {filename}</h3>
                                        {progress < 100 && <LoadingDots />}
                                    </div>

                                    <ProgressBar progress={progress} />
                                </>
                                :
                                <h3 className="mb-5">Error Downloading {filename}?</h3>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Downloading;
