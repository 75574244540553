import React from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
} from 'react-router-dom';
import Wrapper from './components/Wrapper';
import Error from './Pages/Errors';
import LoginPage from './Pages/Login';
import RequireAuth from './common/RequireAuth';
import LogoutPage from './Pages/Logout';
import DocumentsList from './Pages/Documents/List';
import DocumentsWrapper from './Pages/Documents/Wrapper';
import DocumentRoutes from './Pages/Documents/Routes';
import Downloading from './Pages/Documents/Downloading';
import AdminList from './Pages/Users/List';
import AdminWrapper from './Pages/Users/Wrapper';
import AdminRoutes from './Pages/Users/Routes';
import ResetPasswordPage from './Pages/ResetPassword';

const router = createBrowserRouter(createRoutesFromElements(
    <Route element={<Wrapper />} errorElement={<Error />}>
        <Route path={"login"} element={<LoginPage />} />
        <Route path={"logout"} element={<LogoutPage />} />
        <Route path={"reset-password/:token"} element={<ResetPasswordPage />} />

        <Route path={"download/:documentID/:filename"} element={<Downloading />} />

        <Route path={'*'} element={<RequireAuth />} errorElement={<Error />}>
            <Route path="admin-users" element={<AdminWrapper />}>
                <Route index element={<AdminList />} />
                <Route path=":userID" element={<AdminRoutes />} />
            </Route>
            <Route path={'*'} element={<DocumentsWrapper />} >
                <Route index element={<DocumentsList />} />
                <Route path={':documentID'} element={<DocumentRoutes />} />
                <Route path={':documentID/*'} element={<DocumentRoutes />} />
            </Route>
        </Route>
    </Route>
));

export default router;