import React from 'react';
import Error from '../Errors';

import {
    Routes,
    Route,
    useParams
} from 'react-router-dom';
import AdminForm from './Form';
import AdminBreadcrumbs from './Breadcrumbs';
import { AdminProvider } from '../../context/adminProvider';


function AdminRoutes() {
    let { userID } = useParams();


    return (
        <>
            <AdminProvider id={userID as string}>
                <Routes>
                    <Route path="*" errorElement={<Error fullscreen={false} />}  >
                        <Route index element={<AdminForm MenuElement={<AdminBreadcrumbs />} />} />

                        <Route path={"*"} element={<Error status={404} title={'Page not found?'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />

                    </Route>
                </Routes>
            </AdminProvider>
        </>
    );
}

export default AdminRoutes;