import React from "react";
import classNames from "classnames";

interface PropsAvatar {
  size?: "small" | "medium" | "large",
  status?: "primary" | "secondary" | "success" | "danger" | "warning" | "info",
  withShadow?: boolean,
  className?: string,
  children?: any,
}

const Avatar = (props: PropsAvatar) => {
  const { size, status, withShadow, className, children } = props;

  return (
    <span
      className={classNames(
        "avatar",
        {
          "w-16 h-16 text-2xl": size === "medium",
          "w-20 h-20 text-4xl": size === "large",
          "avatar_with-shadow": withShadow,
        },
        className
      )}
    >
      {status ? (
        <span
          className={classNames("status", {
            "bg-blue-400": status === "primary",
            "bg-cyan-400": status === "secondary",
            "bg-green-400": status === "success",
            "bg-red-400": status === "danger",
            "bg-orange-400": status === "warning",
            "bg-indigo-400": status === "info",
          })}
        ></span>
      ) : null}
      {children}
    </span>
  );
};

Avatar.defaultProps = {
  arrow: true,
  status: 'primary',
};

export default Avatar;
