import React from 'react';

const ProgressBar = ({ progress }: { progress: number }) => {

  return (
    // <div className="flex items-center justify-center">
    <div className="overflow-hidden rounded-full bg-gray-200">
      <div className="h-10 rounded-full bg-indigo-600" style={{ "width": `${progress}%` }}></div>
    </div>
    // </div>
  );
};

export default ProgressBar;