import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";

import { urls } from "../../common";

import LoadingDots from "../../components/LoadingDots";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faEdit, faSearch, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../components/Table";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import Tooltip from "../../components/Tooltip";
import AdminsBreadcrumbs from "./Breadcrumbs";
import { handleAddAdmin, handleDeleteAdmin } from "./common";
import DownloadDataButton from "../../components/DownloadDataButton";
import { formatDate } from "../../common/dates";
import { useUser } from "../../context/userProvider";


const AdminList = () => {
    const { user } = useUser();

    const [selectedRows, setSelectedRows] = useState<string[]>();

    const fetchURL = `${urls.remoteURL}users/`;

    const { isLoading, isError, data, refetch } = useQuery(`admins-all`, () => axios.get(fetchURL));

    if (isError) {
        throw { code: 500, message: 'Error getting administrators?' };
    }


    type TableProps = {
        id: string,
        fullname: string,
        firstname: string,
        lastname: string,
        email: number,
        created: number,
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('lastname', {
            cell: info => <>
                {info.row.original.fullname}
                <span className="block md:hidden text-sm font-normal">{info.row.original.email}</span>
            </>,
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('email', {
            cell: info => info.getValue(),
            header: () => 'Email',
            footer: () => 'Email',
            meta: {
                className: 'hidden md:block'
            }
        }),
        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">
                <Tooltip content="Edit">
                    <Link to={`${info.getValue()}`} className="btn btn-green"><span className="sr-only">Edit</span><FontAwesomeIcon icon={faEdit} fixedWidth /></Link>
                </Tooltip>
                <Tooltip content="Delete">
                    <Button
                        type="button" color="red"
                        disabled={user?.id === info.getValue()}
                        onClick={() => handleDeleteAdmin(info.getValue(), refetch)} ><span className="sr-only">Delete</span><FontAwesomeIcon icon={faTrashCan} fixedWidth /></Button>
                </Tooltip>
            </div >
        )
    }

    const [search, setSearch] = useState<string>();
    const [downloadData, setDownloadData] = useState<any[]>([])

    const parseDownloadData = (data: TableProps[]) => {
        return data.map(row => ({
            firstname: row.firstname,
            lastname: row.lastname,
            email: row.email,
            created: formatDate({ time: row.created, formatStr: 'd/M/yyyy' }),
        }))
    }

    useEffect(() => {
        if (data?.data) {
            setDownloadData(parseDownloadData(data?.data));
        }
    }, [data?.data])


    return (
        <>
            <div className="lg:flex items-start w-full" >
                <AdminsBreadcrumbs />

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">
                    {/* Search */}
                    <div className="flex flex-auto" >
                        <label className="form-control-addon-within rounded-full items-center">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                defaultValue={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </div>

                    <div className="flex gap-x-2">
                        <Button color="green" className="uppercase rounded-full px-6"
                            onClick={() => handleAddAdmin(refetch)}>
                            Add New
                        </Button>
                        <DownloadDataButton downloadFilename={`all-admins`} downloadData={downloadData} className="uppercase rounded-full px-3" icon />
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                search,
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default AdminList;