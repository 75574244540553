import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faFile, faFileExcel, faFilePdf, faFilePowerpoint, faFileWord, faImage, faMusic, faVideo } from "@fortawesome/pro-solid-svg-icons";

export const urls = {
  URL: process.env.REACT_APP_URL_BASE,
  remoteURL: process.env.REACT_APP_REMOTE_URL,
}

export const tokenID = "cc-success";
export const STAGE = process.env.REACT_APP_STAGE || 'localhost';

export const _latestObject = ({ sortArr, key }: { sortArr: any[], key: string }) => {
  return sortArr.reduce((latest, current) => {
    return current[key] > latest[key] ? current : latest;
  }, sortArr[0])[0];
}





export const fileIcon = (file: File): IconDefinition => {
  if (file) {
    const fileType = file.type;

    if (fileType.startsWith('image/')) {
      return faImage;
    } else if (fileType.startsWith('video/')) {
      return faVideo;
    } else if (fileType.startsWith('audio/')) {
      return faMusic;
    } else {
      const fileExtension = file.name.includes('.') ? file.name.split('.').pop()?.toLowerCase() : 'file';
      switch (fileExtension) {
        case 'doc':
        case 'docx':
          return faFileWord; // Use your custom FontAwesome icon for Word files
        case 'xls':
        case 'xlsx':
          return faFileExcel; // Use your custom FontAwesome icon for Excel files
        case 'ppt':
        case 'pptx':
          return faFilePowerpoint; // Use your custom FontAwesome icon for PowerPoint files
        case 'pdf':
          return faFilePdf;
        default:
          return faFile; // Default icon for other file types
      }
    }
  }
  return faFile; // Default icon for other file types
}


export function getFileExtension(filename: string): string {
  if (typeof filename !== 'string') { return filename; }
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return '';
  }

  return filename.substring(lastDotIndex + 1);
}

export const getFilenameWithoutExtension = (filename: string): string => {
  if (typeof filename !== 'string') { return filename; }
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex === -1) {
    return filename;
  }

  return filename.substring(0, lastDotIndex);
}