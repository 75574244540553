import { addDays, addHours, addMinutes, addMonths, addYears, endOfDay, format, fromUnixTime, getUnixTime, startOfDay, startOfMonth, subDays, subHours, subMinutes, subMonths, subYears } from 'date-fns';

export const daysofweek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

export const formatDate = ({ time, formatStr = 'do MMMM yyyy', empty = 'n/a' }: { time: number, formatStr?: string, empty?: string }) => {
    if (!time || time === 0) { return empty; }
    return format(fromUnixTime(time), formatStr) //https://date-fns.org/v1.28.5/docs/format
}

export const currentYear = () => new Date().getFullYear();

export const _now = () => getUnixTime(new Date);
export const _ago = ({ n, d, unit = 'minutes', r = 'number', t = 'end' }:
    { n: number, d?: number | Date, unit?: 'minutes' | 'hours' | 'days' | 'months' | 'years', r?: 'number' | 'date', t?: 'start' | 'end' | 'now' }): number | Date => {
    const date =
        t === 'now' ? d ? (typeof d === 'number' ? fromUnixTime(d) : d) : new Date :
            t === 'start' ? startOfDay(d ? (typeof d === 'number' ? fromUnixTime(d) : d) : new Date) :
                endOfDay(d ? (typeof d === 'number' ? fromUnixTime(d) : d) : new Date);
    const ret =
        unit === 'minutes' ? subMinutes(date, n) :
            unit === 'hours' ? subHours(date, n) :
                unit === 'days' ? subDays(date, n) :
                    unit === 'months' ? subMonths(date, n) :
                        subYears(date, n);
    return r === 'number' ? getUnixTime(ret) : ret;
}

export const _away = ({ n, d, unit = 'minutes', r = 'number', t = 'end' }:
    { n: number, d?: number | Date, unit?: 'minutes' | 'hours' | 'days' | 'months' | 'years', r?: 'number' | 'date', t?: 'start' | 'end' }): number | Date => {
    const date = t === 'start' ?
        startOfDay(d ? (typeof d === 'number' ? fromUnixTime(d) : d) : new Date) :
        endOfDay(d ? (typeof d === 'number' ? fromUnixTime(d) : d) : new Date);
    const ret =
        unit === 'minutes' ? addMinutes(date, n) :
            unit === 'hours' ? addHours(date, n) :
                unit === 'days' ? addDays(date, n) :
                    unit === 'months' ? addMonths(date, n) :
                        addYears(date, n);
    return r === 'number' ? getUnixTime(ret) : ret;
}