import React from 'react';
import Error from '../Errors';

import {
    Routes,
    Route,
    useParams
} from 'react-router-dom';
import { DocumentProvider } from '../../context/documentProvider';
import DocumentForm from './Form';
import DocumentBreadcrumbs from './Breadcrumbs';


function DocumentRoutes({ MenuElement }: { MenuElement?: JSX.Element } = {}) {
    let { documentID } = useParams();


    return (
        <DocumentProvider id={documentID as string}>
            <Routes>
                <Route path={'*'} errorElement={<Error status={900} fullscreen={false} />}  >
                    <Route index element={<DocumentForm MenuElement={MenuElement || <DocumentBreadcrumbs />} />} />

                    <Route path={"*"} element={<Error status={404} title={'Page not found?'} message={'Please check the URL in the address bar and try again'} fullscreen={false} hideButton />} />

                </Route>
            </Routes>
        </DocumentProvider>
    );
}

export default DocumentRoutes;