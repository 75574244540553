import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import {
    FilterFn,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    PaginationState,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
    RowData,
} from '@tanstack/react-table'

import {
    rankItem,
} from '@tanstack/match-sorter-utils'


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import Pagination from "./Pagination";


declare module '@tanstack/table-core' {
    interface ColumnMeta<TData extends RowData, TValue> {
        className: string,
        thClassName?: string,
    }
}


const Table = ({
    defaultData, columns,
    options = {
        noData: 'No rows found?',
        pageSize: 10,
        selectDisabled: [],
    }
}: {
    defaultData: any[], columns: any[],
    options?: {
        noData?: string,
        hideSearch?: boolean,
        hideExport?: boolean,
        hideFooter?: boolean,
        compactFooter?: boolean,
        hideSelection?: boolean,
        search?: string,
        hiddenColumns?: string[],
        pageSize?: number,
        paginationRight?: JSX.Element,
        hidePageSize?: boolean,
        BulkButtons?: React.FC,
        selectable?: boolean, selectedRows?: string[], selectableFunction?: (data: string[]) => void,
        selectDisabled?: string[], selectDisabledIcon?: IconDefinition
    }

}) => {

    const [globalFilter, setGlobalFilter] = useState<string>(options.search as string);

    const checkbox = useRef<HTMLInputElement>(null)
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState<boolean>(false)
    const [selectedRows, setSelectedRows] = useState<string[]>(options.selectedRows || []);

    let extraColumns = 0;
    if (options.selectable) { extraColumns++; }
    // if (options.draggable) { extraColumns++; }

    const colspan = columns.length + extraColumns;

    useLayoutEffect(() => {
        //defaultData.filter(x => { return !options.selectDisabled || options.selectDisabled.includes(x.id) }).length
        if (checkbox.current && options.selectable) {
            const isIndeterminate: boolean = (selectedRows.length > 0 && selectedRows.length < defaultData.length) ||
                (!!options.selectDisabled && options.selectDisabled.length > 0);
            const isChecked = selectedRows.length > 0 && (selectedRows.length + (options.selectDisabled?.length ?? 0)) >= defaultData.length
            setChecked(isChecked)
            setIndeterminate(!isChecked && isIndeterminate)
            checkbox.current.indeterminate = !isChecked && isIndeterminate
        }
        if (typeof options.selectableFunction === 'function') {
            options.selectableFunction(selectedRows);
        }
    }, [selectedRows]);

    useEffect(() => {
        if (options.selectable) {
            setSelectedRows(options?.selectedRows || []);
        }
    }, [options?.selectedRows]);

    useLayoutEffect(() => {
        setGlobalFilter(options?.search as string);
    }, [options?.search]);

    function toggleAll() {
        if (options.selectable) {
            setSelectedRows(checked ? [] : defaultData.filter(x => !options.selectDisabled?.includes(x.id)).map(row => row.id))
            setChecked(!checked && !indeterminate)
            setIndeterminate(false)
        }
    }

    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: options.pageSize || 10,
        })

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )


    const table = useReactTable({
        data: defaultData || [],
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            globalFilter,
            columnVisibility: options.hiddenColumns ? options.hiddenColumns.reduce((a: any, b: any) => { a[b] = false; return a }, {}) : [],
            pagination,
        },
        onPaginationChange: setPagination,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        autoResetPageIndex: false,
        sortingFns: {
            myCustomSorting: (rowA: any, rowB: any, columnId: any): number => {
                return rowA.getValue(columnId).value < rowB.getValue(columnId).value ? 1 : -1;
            }
        },
    })

    return (
        <>
            <table className="table table-auto table_hoverable table_striped w-full border-b">
                <thead className=''>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {
                                options.selectable &&
                                <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                                    <input
                                        type="checkbox"
                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6"
                                        ref={checkbox}
                                        checked={checked}
                                        onChange={toggleAll}
                                    />
                                </th>
                            }

                            {headerGroup.headers.map(header => (
                                <th key={header.id} scope="col"
                                    className={classNames(
                                        `py-3.5 pl-4 pr-3`,
                                        header.column.columnDef.meta?.className

                                    )}>
                                    {header.isPlaceholder
                                        ? null
                                        :
                                        <div className={
                                            classNames("flex gap-1 mx-auto", header.column.columnDef.meta?.thClassName)}>
                                            <div
                                                className={
                                                    classNames(
                                                        {
                                                            ' cursor-pointer select-none items-center': header.column.getCanSort(),
                                                        }
                                                    )}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: <FontAwesomeIcon icon={faChevronUp} fixedWidth />,
                                                    desc: <FontAwesomeIcon icon={faChevronDown} fixedWidth />,
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>
                                        </div>
                                    }
                                </th>
                            ))}
                            {/* {
                            options.draggable &&
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-center w-24 text-sm font-semibold text-gray-900">
                                Reorder
                            </th>
                        } */}
                        </tr>
                    ))}
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                    {table.getRowModel().rows.length > 0 ? table.getRowModel().rows.map(row =>
                        // options.draggable ?
                        // <DraggableRow
                        //     key={row.id}
                        //     row={row} options={options} selectedRows={selectedRows} setSelectedRows={setSelectedRows}
                        //     draggedFunction={options.draggedFunction} /> :
                        <StandardRow
                            key={row.id}
                            row={row} options={options} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
                    ) : <tr><td colSpan={colspan} className="relative bg-red-100 " ><p className="text-center">{options.noData || 'No rows found?'}</p></td></tr>}
                </tbody>

            </table>
            {/* <pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre> */}
            {/* <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} className="btn ">x</button> */}

            {!options.hideFooter &&
                <div className={classNames("flex justify-between", {'p-2' : options.compactFooter, 'p-5' : !options.compactFooter})}>
                    <Pagination
                        currentPage={pageIndex}
                        totalCount={table.getPrePaginationRowModel().rows.length}
                        pageSize={pageSize}
                        hidePageSize={!!options.hidePageSize}
                        onPageChange={(page) => { table.setPageIndex(page as number); }}
                        onPageSizeChange={(size) => { table.setPageSize(size as number); }}
                        // className="mt-5"
                        compact={options.compactFooter}
                    />
                    {options.paginationRight && <div className="">{options.paginationRight}</div>}
                </div>
            }

            {selectedRows.length && !options.hideSelection ? (
                <div className="footer-bar">
                    <div className="flex items-center uppercase">
                        <span className="badge badge-blue mr-2">
                            {selectedRows.length}
                        </span>
                        Selected
                    </div>
                    <div className="ml-auto">
                        {!!options.BulkButtons && <options.BulkButtons />}
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default Table;


const StandardRow = ({ row, options, selectedRows, setSelectedRows }: { row: any, options: any, selectedRows: string[], setSelectedRows: (data: string[]) => void }) => {

    return (
        <tr key={row.id} className={
            classNames(
                {
                    'cursor-pointer': options.selectable && !options.selectDisabled?.includes(row.original.id),
                    'danger': row.original.hasOwnProperty('active') && !row.original.active,
                    'selected': selectedRows.includes(row.original.id),
                },
            )
        }
            onClick={(e) => {
                if (!options.selectDisabled?.includes(row.original.id) && options.selectable && !(e.target as HTMLElement).closest('button')) {
                    const selectedText = window.getSelection()?.toString().trim();
                    if (selectedText === '') {
                        setSelectedRows(
                            !selectedRows.includes(row.original.id)
                                ? [...selectedRows, row.original.id]
                                : selectedRows.filter((p) => p !== row.original.id)
                        )
                    }
                }
            }}>
            {
                options.selectable &&
                <td key={`select-${row.id}`} className={`relative w-12 px-6 sm:w-16 sm:px-8 `}>
                    {!options.selectDisabled?.includes(row.original.id) ?
                        <>
                            {selectedRows && selectedRows.includes(row.id) && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                            )}
                            <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6"
                                value={row.id}
                                checked={selectedRows && selectedRows.includes(row.original.id)}
                                onChange={(e) =>
                                    setSelectedRows(
                                        e.target.checked
                                            ? [...selectedRows, row.original.id]
                                            : selectedRows.filter((p) => p !== row.original.id)
                                    )
                                }
                            />
                        </>
                        :
                        options.selectDisabledIcon ?
                            <>
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-orange-600" />
                                <FontAwesomeIcon icon={options.selectDisabledIcon} className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-600  sm:left-6" />
                            </>
                            :
                            <>
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-orange-600" />
                                <input
                                    type="checkbox"
                                    disabled
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-orange-600  sm:left-6"
                                    defaultChecked
                                    readOnly
                                />
                            </>
                    }
                </td>
            }
            {
                row.getVisibleCells().map((cell: any) => (
                    <td key={cell.id} className={
                        classNames(
                            `py-4 pl-4 pr-3 text-sm font-medium text-gray-900`,
                            cell.column.columnDef.meta?.className
                        )}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                ))
            }
        </tr >
    )
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({ itemRank, });
    return itemRank.passed
}
