import React from "react";
import Tippy from "@tippyjs/react";

const Tooltip = (props: {content?: string, className?: string, children: any }) => {
  const { children, ...rest } = props;

  return (
    <Tippy
      theme="light-border tooltip"
      touch={["hold", 500]}
      offset={[0, 12]}
      interactive
      animation="scale"
      appendTo={document.body}
      {...rest}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
