import React from "react";
import { Outlet } from "react-router-dom";


const DocumentsWrapper = () => {



    return (
        <main className="workspace">
            <Outlet />
        </main>
    )


}

export default DocumentsWrapper;