import React from "react";
import axios from "axios";
import { urls } from "../../common";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import * as yup from 'yup';

import { faBiohazard, faBookCopy, faFileUpload, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import fileDownload from "js-file-download";
import DocumentAddForm from "./AddForm";
import { myToast } from "../../common/Toast";


export const handleCopyLinkClick = (string: string) => {
    navigator.clipboard.writeText(`${urls.URL}${string}`);
    myToast({ title: 'Link Copied', icon: faBookCopy, colour: 'green' })
}

export const handleDownloadFile = ({ documentID, filename, cb }: { documentID: string, filename: string, cb?: Function }) => {
    axios.get(`${urls.remoteURL}document/${documentID}/download`, { responseType: 'blob' })
        .then(({ data }) => {
            fileDownload(data, filename)
            if (typeof cb === 'function') {
                cb();
            }
        })
        .catch(async error => {
            const responseObj = JSON.parse(await error.response.data.text());
            myToast({
                title: 'Error',
                message: responseObj.message,
                icon: faFileUpload,
                colour: 'red', timing: 5
            });
        });
}


export const handleAddDocument = ({ cp }: { cp: Function }) => {
    const MySwal = withReactContent(Swal)
    let file: Blob | null;

    const schema = yup.object().shape({
        name: yup.string().required(),
        attachment: yup.mixed().required('File is required').test("fileSize", "The file is too large", (value: { size: number }) => {
            return value.size <= 100_000_000
        }),
    })

    const handleFileUploads = (uploadeFile: Blob | null) => {
        file = uploadeFile;
    }

    MySwal.fire({
        title: <p>Add Document</p>,
        html: <DocumentAddForm inputID="addDocumentFormJSON" setFiles={handleFileUploads} />,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        customClass: {
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            MySwal.resetValidationMessage();
            if (!file) {
                MySwal.showValidationMessage(`Please Select a file`)
                return false;
            }

            const data = JSON.parse((document.getElementById("addDocumentFormJSON") as HTMLInputElement).value);
            const formData = new FormData();
            formData.append("upload", file);
            for (const key of Object.keys(data)) { formData.append(key, data[key]); }

            return schema
                .isValid({
                    name: data.name,
                    attachment: file,
                })
                .then((valid) => {
                    if (valid) {
                        return axios.post(`${urls.remoteURL}documents`, formData,
                            {
                                headers: { "Content-Type": "multipart/form-data" },
                                onUploadProgress: progress => {
                                    const { total, loaded } = progress;
                                    const totalSizeInMB = total as number / 1000000;
                                    const loadedSizeInMB = loaded / 1000000;
                                    const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
                                    // setUploadPercentage(uploadPercentage.toFixed(2));
                                    console.log("total size in MB ==> ", totalSizeInMB);
                                    console.log("uploaded size in MB ==> ", loadedSizeInMB);
                                }

                            }).then((x) => {
                                return x.data
                            }).catch((err) => {
                                MySwal.showValidationMessage(err.response.data.message ?? `Problem Document`)
                                return false;
                            });
                    } else {
                        MySwal.showValidationMessage(`Please check file size!`)
                        return false;
                    }
                });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({
                title: 'Saved',
                icon: faFileUpload,
                colour: 'green', timing: 3
            });
            if (typeof cp === 'function') { cp(); }
        }
    })
}

export const handleDeleteDocument = (id: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete document, this is perminant!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete Document!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}documents`, { data: [id] })
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({
                        title: 'Deleted',
                        icon: faThumbsUp,
                        colour: 'green', timing: 3
                    });
                })
                .catch((error) => {
                    myToast({
                        title: 'Error',
                        message: error.response.data.message || 'Error deleting document?',
                        icon: faBiohazard,
                        colour: 'red', timing: 3
                    });
                });

        }
    })
}

export const handleBulkDeleteDocuments = (ids: string[], companyID?: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete all selected documents, this is perminant!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, delete selected documents!`,
        focusConfirm: false,
        focusCancel: true,
    }).then((result: any) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}documents`, { data: ids })
                .then(() => {
                    if (refetch) { refetch(); }
                    myToast({
                        title: 'Deleted',
                        icon: faThumbsUp,
                        colour: 'green', timing: 3
                    });
                })
                .catch((error) => {
                    myToast({
                        title: 'Error',
                        message: error.response.data.message || 'Error deleting document?',
                        icon: faBiohazard,
                        colour: 'red', timing: 3
                    });
                });

        }
    })
}
