import React from "react";
import axios from "axios";
import { urls } from "../../common";


import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import * as yup from 'yup';
import { faBiohazard, faThumbsUp, faTrash } from "@fortawesome/pro-solid-svg-icons";
import AdminAddForm from "./AddForm";
import { myToast } from "../../common/Toast";

export const handleAddAdmin = (refetch: Function) => {
    const MySwal = withReactContent(Swal)
    const schema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().email(),
    });

    MySwal.fire({
        title: "Add Administrator",
        html: <AdminAddForm inputID="addFormJSON" />,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        width: 800,
        customClass: {
            // popup: 'w-96',
            htmlContainer: 'z-20'
        },
        preConfirm: () => {
            const data = JSON.parse((document.getElementById("addFormJSON") as HTMLInputElement).value);
            console.log(data);

            return schema
                .isValid({
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                })
                .then((valid) => {
                    if (valid) {
                        return axios.post(`${urls.remoteURL}user`, data).then((x) => {
                            return x.data
                        }).catch((err) => {
                            MySwal.showValidationMessage(
                                err.response.data.message ?? `Problem Saving administrator`
                            )
                            return false;
                        });
                    } else {
                        MySwal.showValidationMessage(`Fill in all required fields!`)
                        return false;
                    }
                });
        },
        allowOutsideClick: () => !MySwal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            myToast({ title: 'Saved', colour: 'green', icon: faThumbsUp, timing: 3 })
            refetch();
        }
    })
}



export const handleDeleteAdmin = (id: string, refetch?: Function) => {
    Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${urls.remoteURL}user/${id}`)
                .then(() => {
                    if (typeof refetch === 'function') refetch();
                    myToast({ title: 'Deleted', message: 'The administrator has been deleted.', icon: faTrash, colour: 'green', timing: 3 });
                })
                .catch((error) => {
                    myToast({ title: 'Error', message: error.response.data.message || 'There has been an error deleting this administrator.', icon: faBiohazard, colour: 'red', timing: 3 });
                });
        }
    });
}

