import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBiohazard, faBuilding, faRightFromBracket, faSearch, faUser, faUserCog, faUsers, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";


import { useGlobal } from "../../context/globals";
import { useUser } from "../../context/userProvider";
import Dropdown from "../Dropdown";
import { STAGE, urls } from "../../common";
import Button from "../Button";
import useOnClickOutside from "../../utilities/hooks/useOnClickOutside";
import { myToast } from "../../common/Toast";
import Avatar from "../Avatar";


type PropsSearch = {
    id: string,
    name: string,
    company: string,
    company_id: string,
    town: string,
    postcode: string,
    type: string
};


const Header = () => {
    const { user } = useUser();
    const { global, setGlobal } = useGlobal();

    const [search, setSearch] = useState<string>();
    const [searchResults, setSearchReuslts] = useState<PropsSearch[]>([]);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const [openSearch, setOpenSearch] = useState<boolean>(false)
    const menuBar = useRef(null);

    useEffect(() => {
        if (!!search && search.length > 0) {
            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }

            const newTimeout = setTimeout(() => {
                setOpenSearch(true);
                axios.post(`${urls.remoteURL}search`, { search })
                    .then(({ data }) => {
                        setSearchReuslts(data);
                    })
                    .catch(error => {
                        myToast({ title: 'Error', message: error.response.data.message || 'Error searching.', icon: faBiohazard, colour: 'red', timing: 3 });
                    })
            }, 1000)

            setTypingTimeout(newTimeout);
        }
    }, [search, typingTimeout]);

    useOnClickOutside(menuBar, () => {
        setOpenSearch(false);
    });

    return (
        <header className="top-bar">
            {!!user && false &&
                <button
                    className="menu-toggler"
                    onClick={() => setGlobal(prev => ({ ...prev, menuOpen: !global.menuOpen }))}
                ><FontAwesomeIcon icon={faBars} /></button>
            }

            <span className="brand relative h-full">
                <Link to={'.'} ><img src="/images/cc-logo.png" alt="Coaching Culture" className="max-h-full p-2" />
                    <span className="sr-only">Coaching Culture</span>
                </Link>
            </span>
            {STAGE !== 'production' &&
                <span className="ml-5 bg-red-600 text-white font-bold p-2 rounded-lg">DEV AREA</span>
            }

            {!!user &&

                <div className="hidden md:block ml-10">
                    <Dropdown
                        arrow={false}
                        trigger={'manual'}
                        show={openSearch}
                        content={
                            <div ref={menuBar} className="relative min-w-[400px]">
                                <div className="absolute top-2 right-2">
                                    <Button color="gray" className="z-10 " onClick={() => setOpenSearch(prev => !prev)}><FontAwesomeIcon icon={faXmark} fixedWidth /></Button>
                                </div>
                                <ul className="divide-y divide-gray-100">
                                    {searchResults.length === 0 ? <li className="z-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900">No Results Found?</li> :
                                        searchResults.map(x => (
                                            <li key={x.id + x.type} className="relative rounded-lg p-4 hover:bg-gray-50">
                                                <Link
                                                    to={`./companies/${x.type === 'company' ? x.id : `${x.company_id}/users/${x.id}`}`}
                                                    className=" text-gray-900"
                                                    onClick={() => setOpenSearch(false)}
                                                >
                                                    <p className="font-semibold"><FontAwesomeIcon icon={x.type === 'company' ? faBuilding : faUser}
                                                        fixedWidth
                                                        className="text-gray-400 mr-2" />
                                                        {x.name}
                                                    </p>
                                                    {x.type === 'company' ?
                                                        <div>
                                                            {[x.town, x.postcode].filter(x => x?.length > 0).join(', ')}
                                                        </div>
                                                        :
                                                        <div>
                                                            {x.company}
                                                        </div>
                                                    }
                                                    <span className="absolute inset-0" />
                                                </Link>
                                            </li>
                                        ))}
                                </ul>
                            </div>}
                    >
                        <label className="form-control-addon-within rounded-full items-center hidden">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                defaultValue={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onFocus={() => { if (!!search && search?.length > 3) setOpenSearch(true); }}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </Dropdown>
                </div>
            }

            {!!user &&
                <div className="flex items-center ml-auto">


                    {/* User Menu */}
                    <div>
                        <Dropdown
                            arrow={true}
                            trigger={'click'}
                            content={
                                <div className="w-64">
                                    <div className="p-5">
                                        <h5 className="uppercase">{user.fullname}</h5>
                                        <p>{user.email}</p>
                                    </div>
                                    <hr />
                                    <div className="p-5 space-y-2">
                                        <Link
                                            to={'admin-users/' + user.id}
                                            className="flex gap-2 text-gray-700 hover:text-blue-700"
                                        >
                                            <FontAwesomeIcon icon={faUserCog} />
                                            View Profile
                                        </Link>
                                        <Link
                                            to={'admin-users'}
                                            className="flex gap-2  text-gray-700 hover:text-blue-700"
                                        >
                                            <FontAwesomeIcon icon={faUsers} fixedWidth />
                                            All Admins
                                        </Link>
                                    </div>
                                    <hr />
                                    <div className="p-5">
                                        <Link
                                            to="logout"
                                            className="flex gap-2 items-center text-gray-700 dark:text-gray-500 hover:text-blue-700"
                                        >
                                            <FontAwesomeIcon icon={faRightFromBracket} fixedWidth />
                                            Logout
                                        </Link>
                                    </div>
                                </div>
                            }
                        >
                            <button className="ml-4">
                                <Avatar>{user.firstname?.[0]}{user.lastname?.[0]}</Avatar>
                            </button>
                        </Dropdown>
                    </div>
                </div>
            }
        </header >
    )
}

export default Header;
