import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from "axios";

import { urls } from "../../common";
import { PropsUser } from './types';
import { faBiohazard, faSave } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../components/form/Label';
import classNames from 'classnames';
import Input from '../../components/form/Input';
import SwitchControl from '../../components/form/Switch';
import Button from '../../components/Button';
import { useAdmin } from '../../context/adminProvider';
import { myToast } from '../../common/Toast';
import { useUser } from '../../context/userProvider';
import { Link } from 'react-router-dom';

function Admin({ MenuElement }: { MenuElement?: JSX.Element }) {
    const { admin, setAdmin } = useAdmin();
    const { user } = useUser();

    const schema = yup
        .object()
        .shape({
            firstname: yup.string().required(),
            lastname: yup.string().required(),
            email: yup.string().email().required(),
            _password: yup.string()
                .trim()
                .nullable()
                // .transform((curr, orig) => (orig === "" ? null : curr))
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                    {
                        message: "Must contain 8 characters, one uppercase, one lowercase, one number, and one special character",
                        excludeEmptyString: true,
                    }),

            confirm_password: yup.string().when('_password', {
                is: (password: any, schema: any) => password && password.length > 0,
                then: yup
                    .string()
                    .required('Please confirm password')
                    .oneOf([yup.ref('_password')], 'Passwords do not match'),
            }),

        });

    type FormProps = PropsUser & { _password?: string, confirm_password?: string };



    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<FormProps>({
        resolver: yupResolver(schema),
    });


    const submit = (data: FormProps) => {
        if (data._password && data._password.length > 0) {
            data.password = data._password;
            delete data._password;
            delete data.confirm_password;
        }

        axios.put(`${urls.remoteURL}user/${admin?.id}`, data).then(res => {
            setAdmin((old) => ({
                ...old,
                ...data,
            }));
        })
            .then(() => {
                setValue('_password', '');
                setValue('confirm_password', '');
                myToast({ title: 'Saved', colour: 'green', icon: faSave, timing: 3 });
            })
            .catch((error) => {
                myToast({ title: 'Error', message: error.response.data.message || error.message, colour: 'red', icon: faBiohazard, timing: 3 });
            })
    }

    return (
        <form onSubmit={handleSubmit((d) => submit(d))}>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between">
                <div>
                    {MenuElement}
                </div>
                <div className="flex gap-2 my-3 sm:mt-0 sm:ml-4">
                    <Link to="../.." className="btn px-6 rounded-full">Back</Link>
                    <Button
                        type="submit"
                        color="green"
                        className="rounded-full px-6"
                    >
                        Save <FontAwesomeIcon icon={faSave} fixedWidth className="ml-2" />
                    </Button>
                </div>
            </div>

            <div className="grid md:grid-cols-2 gap-4 mb-4">
                <div className="card p-5 space-y-4">
                    <h3 className="border-b pb-2">Contact Details</h3>
                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.firstname })} required htmlFor="firstname">
                            Name
                        </Label>
                        <div className="grid grid-cols-2 gap-2">
                            <Input
                                {...register('firstname')}
                                placeholder="John"
                                defaultValue={admin?.firstname}
                                className={classNames({ 'invalid': errors.firstname })} />
                            <Input
                                {...register('lastname')}
                                placeholder="Doh"
                                defaultValue={admin?.lastname}
                                className={classNames({ 'invalid': errors.lastname })} />
                        </div>
                    </div>

                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.email })} required htmlFor="email">
                            Email
                        </Label>
                        <Input
                            {...register('email')}
                            placeholder="email@domain.com"
                            defaultValue={admin?.email}
                            className={classNames({ 'invalid': errors.email })} />
                    </div>
                </div>

                <div className="card p-5 space-y-4">
                    <h3 className="border-b pb-2">Security</h3>

                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors._password })} htmlFor="email">
                            Password
                        </Label>
                        <Input
                            type="password"
                            autoComplete="new-password"
                            {...register('_password')}
                            placeholder="***************"
                            className={classNames({ 'invalid': errors._password })} />
                        <small className="text-red-700">{errors._password?.message}</small>
                    </div>

                    <div>
                        <Label className={classNames("block mb-2", { 'invalid': errors.confirm_password })} htmlFor="email">
                            Confirm Password
                        </Label>
                        <Input
                            type="password"
                            autoComplete="new-password"
                            {...register('confirm_password')}
                            placeholder="***************"
                            className={classNames({ 'invalid': errors.confirm_password })} />
                        <small className="text-red-700">{errors.confirm_password?.message}</small>
                    </div>


                    <div className="flex items-center">
                        <div className="flex-1">
                            <Label className={classNames("block")} htmlFor="firstname">
                                Active
                            </Label>
                            <small className="text-muted">Will deactivate company user and disable logging in.</small>
                        </div>
                        <div className="">
                            <SwitchControl
                                name="active"
                                defaultValue={!!admin?.active as unknown as string}
                                control={control}
                                disabled={admin?.id === user?.id}
                            />
                        </div>
                    </div>

                </div>


            </div>

        </form>
    )

}

export default Admin;