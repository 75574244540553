import React, { useEffect, useState } from 'react';
import Breadcrumb, { BreadcrumbItem } from "../../components/Breadcrumb";
import { useParams } from 'react-router-dom';
import type { Params } from 'react-router-dom';

function AdminsBreadcrumbs() {
    const params = useParams<Params>();
    const [current, setCurrent] = useState<string[]>();

    const [items, setItems] = useState<JSX.Element[]>();

    useEffect(() => {
        if (params['*'] === 'companies/add') {
            const breadcrumbItems: JSX.Element[] = [
                <BreadcrumbItem key="view-all" link="/admin-users">View All</BreadcrumbItem>,
                <BreadcrumbItem key="user">Add</BreadcrumbItem>
            ];
            setItems(breadcrumbItems);
        } else {
            const breadcrumbItems: JSX.Element[] = [];
            setItems(breadcrumbItems);
        }
    }, [current])

    useEffect(() => {
        if (params['*']) {
            const a = params['*'].split('/');
            setCurrent(a);
        } else {
            setCurrent([]);
        }
    }, [params])


    if (!items) {
        return (<Breadcrumb title="Administrators" />)
    }

    return (
        <>
            <Breadcrumb title="Administrators">
                {items?.map(x => x)}
            </Breadcrumb>
        </>
    );
}

export default AdminsBreadcrumbs;